import WebFn, { callAppFunc } from './jsbridge';

const setSearchAddToCartFailed = (value) => {
  callAppFunc('saveRateData', {
    key: 'search_add_to_cart_failed',
    value: value,
  });
};
const setUseCouponFailed = (value) => {
  callAppFunc('saveRateData', { key: 'use_coupon_failed', value: value });
};
const setOrderFlowFailed = (value) => {
  callAppFunc('saveRateData', { key: 'order_flow_failed', value: value });
};
const setOrderOvertime = (value) => {
  callAppFunc('saveRateData', { key: 'order_overtime', value: value });
};

export {
  setSearchAddToCartFailed,
  setUseCouponFailed,
  setOrderFlowFailed,
  setOrderOvertime,
};
