import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import parse from 'html-react-parser';
import styled, { css } from 'styled-components';
import { API_RESPONSE } from 'api/_const';
import store, { actions, getters } from '../../redux/store';
import { useSelector } from 'react-redux';

import Button from 'components/button';
import HeaderBar from 'components/header-bar';
import InputText from 'components/input-text';
import { EmailCombineDialog } from 'components/dialog';

import { setUseCouponFailed } from '../../utils/_RateData';
import { useAPI } from 'utils/api';

const Container = styled.div``;

const PromoDetail = styled.div`
  background: #f2f2f2;
  padding: 16px 20px;
  height: calc(100vh - 47vh);
  overflow: auto;
`;

const PromoDetailTitle = styled.h2`
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #3b3516;
  margin-bottom: 8px;
`;

const PromoDetailInfo = styled.p`
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #3b3516;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const PromoDetailInfoFailed = styled.div`
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 87px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -4px 10px;
  background-color: rgb(255, 255, 255);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  ${(props) =>
    !props.addMode &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    `}
`;

const PromoCodeInputWrapper = styled.div`
  padding: 16px 20px;
`;

const PromoCodePage = ({ location }) => {
  const api = useAPI();

  const [addMode, setAddMode] = useState(true);
  const [isFailed, setIsFailed] = useState(true);
  const [couponValue, setCouponValue] = useState('');
  const [couponInfo, setCouponInfo] = useState();
  const [showEmail, setShowEmail] = useState(false);
  const [couponMessage, setCouponMessage] = useState('');
  const successOrderCount = useSelector((state) =>
    getters.getSuccessOrderCount(state)
  );

  const handleUseCouponFailed = () => {
    if (successOrderCount >= 4) setUseCouponFailed(true);
  };

  const addCoupon = () => {
    api
      .coupons({ code: couponValue })
      .then((res) => {
        if (res.code === API_RESPONSE.SUCCESS) {
          setAddMode(false);
          setIsFailed(false);
          setCouponInfo(res.data);
          return;
        } else if (res.code === API_RESPONSE.COUPON_EMAIL_COMBINE) {
          setShowEmail(true);
          handleUseCouponFailed();
          return;
        } else {
          setIsFailed(false);
          handleUseCouponFailed();
          alert(res.reason, () => setIsFailed(true));
        }
      })
      .catch((error) => {
        handleUseCouponFailed();
        console.log(error);
      });
  };

  const removeCoupons = () => {
    api
      .removeCoupons()
      .then((res) => {
        if (!res) {
          return;
        }
        setAddMode(true);
        setCouponInfo();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setCouponMessage(location.state.couponMessage);
  }, []);

  return (
    <>
      <HeaderBar title="新增折扣碼" goBack={() => navigate(-1)} />
      <Container>
        <PromoCodeInputWrapper>
          <InputText
            className="input-promo-code"
            htmlFor="promo-code"
            label="輸入折扣碼"
            text={couponValue}
            onChangeValue={setCouponValue}
            message={addMode ? '' : '成功新增'}
          />
        </PromoCodeInputWrapper>

        {!addMode && couponInfo && (
          <PromoDetail>
            <PromoDetailTitle>{couponInfo.name}</PromoDetailTitle>
            <PromoDetailInfo>{couponInfo.description}</PromoDetailInfo>
          </PromoDetail>
        )}

        {isFailed && (
          <PromoDetail>
            <PromoDetailInfoFailed>
              {parse(couponMessage)}
            </PromoDetailInfoFailed>
          </PromoDetail>
        )}
        <Footer addMode={addMode}>
          {addMode ? (
            <Button text="新增" onClick={() => addCoupon()} />
          ) : (
            <>
              <Button text="清除" gray onClick={() => removeCoupons()} />
              <Button text="完成" onClick={() => navigate(-1)} />
            </>
          )}
        </Footer>
      </Container>

      {showEmail && (
        <EmailCombineDialog
          show={showEmail}
          onClose={() => {
            setShowEmail(false);
          }}
          onUpdateFinished={() => {
            setShowEmail(false);
            alert('資料更新成功', () => {});
          }}
        ></EmailCombineDialog>
      )}
    </>
  );
};

export default PromoCodePage;
